.cb-dashboardHeader {
    background-color: var(--chat-window-bg-color);
    box-shadow: rgba(0, 0, 0, 0.05) 2px -3px 0px 0px inset;
    height: 80px;
    padding: 20px 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

.aim-header-icon-name {
    -webkit-text-stroke: 1px;
}


@media (max-width:500px) {
    .cb-dashboardHeader {
        padding: 10px 20px;
    }
}