@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-Bold.eot");
  src: url("./assets/webfonts/Supreme-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-Bold.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-Bold.woff") format("woff"),
    url("./assets/webfonts/Supreme-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-Extralight.eot");
  src: url("./assets/webfonts/Supreme-Extralight.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-Extralight.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-Extralight.woff") format("woff"),
    url("./assets/webfonts/Supreme-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-ExtralightItalic.eot");
  src: url("./assets/webfonts/Supreme-ExtralightItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-ExtralightItalic.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-ExtralightItalic.woff") format("woff"),
    url("./assets/webfonts/Supreme-ExtralightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-Italic.eot");
  src: url("./assets/webfonts/Supreme-Italic.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-Italic.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-Italic.woff") format("woff"),
    url("./assets/webfonts/Supreme-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-BoldItalic.eot");
  src: url("./assets/webfonts/Supreme-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-BoldItalic.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-BoldItalic.woff") format("woff"),
    url("./assets/webfonts/Supreme-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-MediumItalic.eot");
  src: url("./assets/webfonts/Supreme-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-MediumItalic.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-MediumItalic.woff") format("woff"),
    url("./assets/webfonts/Supreme-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-Thin.eot");
  src: url("./assets/webfonts/Supreme-Thin.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-Thin.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-Thin.woff") format("woff"),
    url("./assets/webfonts/Supreme-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-LightItalic.eot");
  src: url("./assets/webfonts/Supreme-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-LightItalic.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-LightItalic.woff") format("woff"),
    url("./assets/webfonts/Supreme-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-ThinItalic.eot");
  src: url("./assets/webfonts/Supreme-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-ThinItalic.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-ThinItalic.woff") format("woff"),
    url("./assets/webfonts/Supreme-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-Light.eot");
  src: url("./assets/webfonts/Supreme-Light.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-Light.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-Light.woff") format("woff"),
    url("./assets/webfonts/Supreme-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-Regular.eot");
  src: url("./assets/webfonts/Supreme-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-Regular.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-Regular.woff") format("woff"),
    url("./assets/webfonts/Supreme-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-Medium.eot");
  src: url("./assets/webfonts/Supreme-Medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-Medium.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-Medium.woff") format("woff"),
    url("./assets/webfonts/Supreme-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-ExtraboldItalic.eot");
  src: url("./assets/webfonts/Supreme-ExtraboldItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-ExtraboldItalic.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-ExtraboldItalic.woff") format("woff"),
    url("./assets/webfonts/Supreme-ExtraboldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Supreme";
  src: url("./assets/webfonts/Supreme-Extrabold.eot");
  src: url("./assets/webfonts/Supreme-Extrabold.eot?#iefix") format("embedded-opentype"),
    url("./assets/webfonts/Supreme-Extrabold.woff2") format("woff2"),
    url("./assets/webfonts/Supreme-Extrabold.woff") format("woff"),
    url("./assets/webfonts/Supreme-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}



.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Supreme";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  border: 1px solid #d5d5d5;
}


::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

::-webkit-scrollbar-track:horizontal {
  border-radius: 0;
  background: #eeeeee;
}

::-webkit-scrollbar-thumb:horizontal {
  border-radius: 0;
  background: #b0b0b0;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 25px;
}

.mt-6 {
  margin-top: 30px;
}

.mt-7 {
  margin-top: 35px;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.w-100 {
  width: 100%;
}

.justify-end {
  justify-content: flex-end;
}

.f-16 {
  font-size: 1rem;
}

.f-32 {
  font-size: 2rem;
}

.f-20 {
  font-size: 20px;
}

.f-24 {
  font-size: 24px;
}

.f-28 {
  font-size: 28px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}