.chatBotDashboard-home {
  background-color: red;
}

:root {
  color: #049ff3;
  color: #04224b;

  --chat-window-total-width: 440px;
  --chat-window-height: 500px;
  --chat-window-fullscreen-width: 80%;
  --chat-window-fullscreen-height: calc(100vh -150px);
  --chat-window-fullscreen-width-upto1000: 700px;
  --chat-window-fullscreen-height-upto1000: 600px;
  --chat-window-color-theme: linear-gradient(
    221deg,
    #04224b 15.27%,
    #0c7ab6 84.48%
  );
  --chat-chat-background-theme: linear-gradient(
    221deg,
    #04224b 15.27%,
    #0c7ab6 84.48%
  );
  --chat-theme-dark-color: #1b4888;
  --chat-window-bg-color: #fff;
  --chat-window-bg-color-dark: #1c202e;
  --chat-window-bg-color-dark-01: #7e818da6;
  --chat-send-button: linear-gradient(221deg, #04224b 15.27%, #0c7ab6 84.48%);
  --chat-user-msg-bg: #ddd;
  --chat-header-bg: #215fff;
  --chat-color-red-01: #ff0000;
  --chat-color-green-01: #33bb47;
  --chat-gradient-blue: linear-gradient(221deg, #04224b 15.27%, #049ff3 84.48%);
  --chat-gradient-primary-gradient: linear-gradient(
    221deg,
    #04224b 15.27%,
    #049ff3 84.48%
  );
  --chat-color-black: #000000;
  --chat-color-white: #fff;
  --chat-color-light-purple: #a3dcfa;
}

#chat-container {
  background-image: url("../assets/images/homePageGradientBg.png");
  position: absolute;
  height: calc(100% - 80px);
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.cb-dashboard-content {
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--chat-color-white);
}

.cb-dashboard-title {
  width: 60%;
  font-size: 85px;
  font-weight: 900;
  top: 20%;
  text-align: center;
  -webkit-text-stroke-width: 1px;
  line-height: 115px;
}

.cb-dashboard-title-italic {
  font-size: 38px;
  font-weight: 900;
  font-style: italic;
  /* text-transform: ; */
}

.cb-dashboard-btn {
  margin-top: 1rem;
  backdrop-filter: blur(3px);
  width: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.05);
  opacity: 1;
  border-radius: 15px;
  padding: 12px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.cb-dashboard-btn-submit {
  background: white;
  width: 100%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.cb-dashboard-btn-submit-field {
  background: linear-gradient(221deg, #992fd1 15.27%, #0185ff 84.48%);
  padding: 10px 20px;
  color: white;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
}

.cb-dashboardavatar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cb-btn {
  background: var(--chat-gradient-primary-gradient);
  padding: 10px 20px;
  color: var(--chat-window-bg-color);
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.chat-btn {
  position: fixed;
  right: 50px;
  bottom: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  background: var(--chat-gradient-primary-gradient);
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 20%;
  /* opacity: 0.8; */
  transition: opacity 0.3s;
  box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.4);
}

.icon {
  transform: scale(1.2);
}

.chat-btn:hover,
.chat-submit:hover {
  opacity: 1;
}

.chat-popup {
  position: fixed;
  bottom: 80px;
  right: 120px;
  height: var(--chat-window-height);
  width: var(--chat-window-total-width);
  background-color: var(--chat-window-bg-color-dark-01);
  flex-direction: column;
  justify-content: space-between;
  /* padding: 0.75rem; */
  box-shadow: 5 5px 5px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  transition: all 0.5s ease-out;
}

.cb-user-login-modal {
  position: absolute;
  backdrop-filter: blur(3px);
  height: var(--chat-window-height);
  width: var(--chat-window-total-width);

  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cb-user-login-body {
  width: 75%;
  background: var(--chat-gradient-blue);
  padding: 25px;
  border-radius: 15px;
}

.chat-popup-fullscreen {
  position: absolute;
  height: calc(100vh - 180px) !important;
  width: var(--chat-window-fullscreen-width) !important;
}

.chat-header {
  display: flex;
  align-items: center;
  /* background-color: dodgerblue; */
  background: var(--chat-window-color-theme);
  /* background-image: var(--chat-header-bg); */
  color: white;
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  padding: 10px;
  /* margin-bottom: 10px; */
  align-items: center;
  justify-content: space-between;
  max-height: 80px;
  min-height: 60px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.chat-header-left {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.cb-chatbot-fullscreen {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: 0.5rem;
  cursor: pointer;
}

.chat-header .bot-title {
  display: flex;
  justify-content: flex-start;
  float: left;
}

.cb-userrole {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0px;
}

.expand-chat-window {
  width: 50px;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
}

.expand-chat-window:hover {
  transform: scale(1.5);
  outline: none;
  border: none;
}

.chat-area {
  height: 88%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--chat-window-bg-color-dark);
}

.bot-msg {
  display: flex;
  /* align-items: center; */
  gap: 8px;
  margin: 15px;
}

.bot-msg-loader {
  align-items: center;
}

.bot-msg-loader-text {
  color: var(--chat-color-white);
  display: flex;
  align-items: center;
  gap: 10px;
}
.bot-img {
  width: 30px;
  margin-right: 15px;
}

.bot-msg .msg {
  position: relative;
  background: var(--chat-chat-background-theme);
  color: white;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  max-width: 450px;
  word-break: break-word;
}

.bot-msg-fullscreen .msg {
  max-width: 500px !important;
}

.cb-markdown {
  overflow: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 0.5rem 0rem;
}

th,
td {
  border: 2px dotted #dfe2e5;
  padding: 8px;
  text-align: left;
  width: 400px;
  font-size: 14px;
  white-space: nowrap; /* Prevent text wrapping */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
}

.user-msg {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}

.user-msg .msg {
  background-color: var(--chat-user-msg-bg);
  color: black;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  word-break: break-word;
  max-width: 250px;
}

.user-msg-fullscreen .msg {
  max-width: 450px !important;
}

.chat-input-area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ccc;
  height: 60px;
  padding: 10px;
  gap: 0.5rem;
}

.chat-input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 5px;
  height: 2.2rem;
  outline-color: var(--chat-window-color-theme);
  outline: none;
}

.chat-submit {
  padding: 0.5rem 1rem;
  /* margin-left: 0.5rem; */
  background: var(--chat-send-button);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  /* opacity: 0.8;
    transition: opacity 0.3s; */
}

.show {
  display: flex;
}

.response-btns {
  display: flex;
  flex-direction: column;
}

.btn-primary {
  background-color: var(--chat-user-msg-bg);
  border: 1px solid var(--chat-window-color-theme);
  /* outline: none; */
  /* display: inline-block; */
  color: var(--chat-color-black);
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  /* font-weight: 600; */
}

.btn-primary:hover {
  /* background: var(--chat-window-color-theme); */
  /* color: #fff; */
  transform: scale(1.03);
}

.ai-chatbot-full-screen-img {
  margin-top: 32px;
  width: 100%;
  object-fit: cover;
}

.chat-bot-msg-image {
  max-width: 80%;
  position: relative;
}

.msg-image {
  margin-top: 5px;
  max-width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.chat-bot-fullscreen-icon {
  position: absolute;
  background: var(--chat-window-color-theme);
  padding: 2px 5px;
  border-radius: 5px;
  top: 10px;
  right: 5px;
  cursor: pointer;
  color: #fff;
}

.typing {
  width: 4em;
  /* height: 2em; */
  position: relative;
  padding: 10px;
  /* margin-left: 5px; */
  /* background: var(--chat-window-color-theme); */
  border-radius: 5px;
  margin: 6px 0 0 1px;
}

.cb-chat-voice-icon {
  cursor: pointer;
  align-self: flex-end;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

.cb-like-dislike-bot-msg {
  position: absolute;
  gap: 5px;
  padding-top: 5px;
  right: 3px;
  bottom: -15px;
  display: flex;
  visibility: hidden;
  background: var(--chat-color-light-purple);
  border-radius: 10px;
  padding: 3px 10px;
}

.bot-msg:hover .cb-like-dislike-bot-msg {
  visibility: visible;
}

.cb-likModal-title {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cb-likeModal-icon {
  background-color: var(--chat-color-green-01);
  padding: 10px;
  border-radius: 50%;
}

.cb-dislikeModal-icon {
  background-color: var(--chat-color-red-01);
  padding: 10px 10px 5px 10px;
  border-radius: 50%;
}

.cb-neutralModal-icon {
  background: var(--chat-window-color-theme);
  padding: 10px 10px 8px 10px;
  border-radius: 50%;
}

.cb-likeModal-name {
  font-size: 28px;
}

.cb-likeModal-input {
  font-size: 20px;
}

.cb-head-userrole {
  font-size: 16px;
  font-weight: 600;
}

.cb-common-input {
  height: 45px;
  font-weight: 600;
  font-size: 16px;
}

.cb-common-button {
  padding: 0px 20px;
  font-weight: 600;
  height: 38px;
  font-size: 16px;
}

/* dot loader */
.dot-falling {
  position: relative;
  left: -9999px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--chat-user-msg-bg);
  color: var(--chat-user-msg-bg);
  box-shadow: 9999px 0 0 0 var(--chat-user-msg-bg);
  animation: dot-falling 1s infinite linear;
  animation-delay: 0.1s;
}

.dot-falling::before,
.dot-falling::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-falling::before {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--chat-user-msg-bg);
  color: var(--chat-user-msg-bg);
  animation: dot-falling-before 1s infinite linear;
  animation-delay: 0s;
}

.dot-falling::after {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--chat-user-msg-bg);
  color: var(--chat-user-msg-bg);
  animation: dot-falling-after 1s infinite linear;
  animation-delay: 0.2s;
}

.bot-msg .cb-msg-audio-player {
  padding: 5px;
  background-color: var(--chat-color-light-purple);
  border-radius: 10px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
  /* margin-top: 10px; */
}

.d-none {
  display: none;
}

.cb-chat-poke-desk {
  min-width: 105px;
}
.chat-secondary-btn {
  background: var(--chat-theme-dark-color) !important;
  color: white !important;
  /* border: 1px solid var(--chat-color-white); */
  /* font-weight: 600; */
}
@keyframes dot-falling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }

  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 var(--chat-user-msg-bg);
  }

  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dot-falling-before {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }

  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 var(--chat-user-msg-bg);
  }

  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dot-falling-after {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }

  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 var(--chat-user-msg-bg);
  }

  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

.chatbot-back-to-home {
  display: none;
}

@media (max-width: 1840px) {
  .cb-dashboard-title {
    font-size: 60px;
    line-height: normal;
  }

  .cb-dashboard-btn-submit {
    padding: 10px;
  }

  .cb-dashboard-btn-submit-field {
    font-size: 18px;
  }
}

@media (max-width: 1000px) {
  .cb-dashboard-title {
    font-size: 40px;
    line-height: normal;
  }

  .cb-dashboard-title-italic {
    font-size: 24px;
    font-weight: 900;
    font-style: italic;
    /* text-transform: ; */
  }

  .cb-dashboard-btn-submit {
    padding: 10px;
  }

  .cb-dashboard-btn-submit-field {
    font-size: 16px;
  }

  .cb-chatbot-fullscreen .cb-fullscreen-icon {
    display: none;
  }

  .chat-popup-fullscreen {
    height: var(--chat-window-fullscreen-height-upto1000) !important;
    width: var(--chat-window-fullscreen-width-upto1000) !important;
  }
}

@media (max-width: 600px) {
  .chat-popup {
    bottom: 0;
    right: 0;
    /* top: 0; */
    width: 100vw;
    height: -webkit-fill-available;
    z-index: 1;
    border-radius: 0px;
    /* height: 100%; */
  }

  .bot-msg .msg {
    max-width: 290px;
  }

  .chat-header {
    border-radius: 0px;
  }

  .chatbot-back-to-home {
    display: flex;
    cursor: pointer;
  }

  .chat-btn {
    right: 20px;
    bottom: 20px;
  }

  .cb-like-dislike-bot-msg {
    bottom: -13px;
  }

  .cb-chatbot-fullscreen .cb-fullscreen-icon {
    display: none;
  }

  .cb-user-login-modal {
    width: 90vw;
  }

  .cb-dashboard-title {
    width: 85%;
    font-size: 32px;
    line-height: normal;
  }

  .cb-dashboard-title-italic {
    font-size: 16px;
    font-weight: 900;
    font-style: italic;
    /* text-transform: ; */
  }

  .cb-dashboard-btn-submit {
    padding: 10px;
  }

  .cb-dashboard-btn-submit-field {
    font-size: 16px;
  }
}
